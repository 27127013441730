/*
.sidenav .active.nav-item .nav-link-text {
    color: #fff,
}
*/
a, a:not([href]):not([class]) {
  color: $link-color;
  cursor: pointer;
  &:hover {
    color:$link-hover-color
  }
}
.finger {
  cursor: pointer;
}
.text-dark {
    color: theme-color("dark");
}
.sk_typography_p {
    font-size: $font-size-base;
}

/* TYPOGRAPHY */

.header-bold {
    font-weight: $font-weight-extra-bold;
}
.text-bold {
    font-weight: $font-weight-bold;
}
.text-inline {
    display: inline-block;
}
.sk_typography_p {
    font-size: $font-size-number-field;
    font-weight: $font-weight-normal;
}
.sk_font_text {
    font-size: $font-size-text-field;
}
.sk_text_wrap {
    white-space: normal;
    min-width: 200px;
}

/* TABLES */

.table .thead-dark th {
    color: #fff;
    background-color: theme-color("primary");
}
table {
    border:none;
    border-collapse: collapse;
}

table.vertical_borders td {
    border-left: 1px solid $table-border-color;
    border-right: 1px solid $table-border-color;
}

table td:first-child {
    border-left: none;
}

table td:last-child {
    border-right: none;
}

.table_auto .subtitle {
  background-color: $table-auto-subtitle-color !important;
  color: #000;
  font-weight: $font-weight-bold;
}
.table_auto .subtitle td {
  border-color: $table-auto-subtitle-color;
  border-width: 1px;
  border-style: solid;
}
.table th.sk_table_header, .sk_table_header {
  white-space: normal;
}
.lightGreenBackground {
  background-color: #ADF6A7;
}
.lightRedBackground {
  background-color: #FF7F7F;
}

/* INPUTS */

.form-control {
    font-size: $font-size-base;
    padding: 0.425rem 0.45rem;
}
.inputFileHidden {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    zIndex: -1;
    width: 30;
}
.sk_input {
    min-width: 64px;
    padding: 4px;
}
.sk_select_multi_select, .custom_bootstrap_input, .sk_input_select {
    min-width: 100px;
    padding-left: 2px;
    padding-right: 2px;
}
.sk_input_product_supplier_code {
    min-width: auto;
    width: auto;
}

/* HELPERS */
.cursor_pointer {
    cursor: pointer;
}
.w-32 {
    width: 32px;
}
.h-32 {
    height: 32px;
}
.border-left-3 {
    border-left-width:3px !important;
}
.s-5 {
  font-size: 200%;
}
.flex-1 {
    flex: 1;
}
.no-wrap {
    white-space: nowrap;
}

/* PAD */
.pad-warning {
    background-color: theme-color("warning");
}
.pad-danger {
    background-color: theme-color("danger");
}
.pad-success {
    background-color: theme-color("success");
}

/* DASHBOARD */
.dashboard_main_content_sidenav_closed {
    margin-left: 64px;
}

/* SIDENAV */
.sidebar_sidenav_open {
    margin-right: 128px;
}
.sk_nav_root {
  .nav-link-text {
    margin-left: 12px;
  }
}
.sk_nav_inner {
  margin-left: 32px;
  .nav-link-text {
    margin-left: 12px;
  }
}
.sk_eu_logo_container {
  height: 50px;
  position: absolute;
  bottom: 0;
  /*
  left: 50%;
  transform: translateX(-50%);
  */
  margin-bottom: 10px; 
}
/*
.sk_eu_logo_image {
  max-width: 50px;
}
*/

