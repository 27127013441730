.sk_color_navigation {
  background-color: #212529;
  color: #fff;
}
table, table.sk_table {
  .sk_color_table_head {
    background-color: #525f7f;
    color: #fff;
  }
  .sk_color_table_row.selected {
    background-color: #aabeec;
  }
  tbody {
    tr:hover {
      background-color: #ffe3bc;
    }
    /* Style for even rows */
    tr:nth-child(even) {
      background-color: #f0f1f3;
      color: #000;
      &.sk_color_row_active {
        background-color: #aabeec;
      }
      &.sk_color_row_alert {
        background-color: #fdd1da;
      }
      &:hover {
        background-color: #ffe3bc;
      }
    }
    /* Style for odd rows */
    tr:nth-child(odd) {
      background-color: #ffffff;
      color: #000;
      &.sk_color_row_active {
        background-color: #aabeec;
      }
      &.sk_color_row_alert {
        background-color: #fdd1da;
      }
      &:hover {
        background-color: #ffe3bc;
      }
    }
  }
}

.sk_color_button_save {
  background-color: #45c915;
  color: #ffffff;
}
.sk_color_button_cancel {
  background-color: #f7fafc;
  color: #000000;
}
.sk_color_button_external_link {
  background-color: #ff9e19;
  color: #ffffff;
}
.sk_color_alert {
  background-color: #f5365c;
  color: #ffffff;
}
.sk_color_alert_symbol {
  background-color: #fdd1da;
  color: #f80031;
}
.sk_color_warning_symbol {
  background-color: #ffe3bc;
  color: #e59400;
}
.sk_color_deactivated {
  background-color: #ffffff;
  color: #000000;
}
.sk_color_row_active {
  background-color: #aabeec;
}
.sk_color_row_alert {
  background-color: #fdd1da;
}