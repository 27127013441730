.button_loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
}
.sk_variant_link {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
  padding-left: 0 !important;
  padding-right:0 !important;
}
.sk_text_link {
  font-size: 80%;
  font-weight: 400;
  padding-bottom: 0.25rem !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right:0 !important;
}