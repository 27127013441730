.table_auto {
    
}
.table_sticky_header thead th.sk_table_header {
  position: sticky;
  top: 0px;
  z-index: 2;
}
.table_sticky_header thead th.sk_second_header {
  position: sticky;
  top: 72px;
  z-index: 2;
}
.table .thead-dark th a.table_header_link {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.limit_column_size {
    word-wrap: break-word;
    max-width: 150px !important;
    text-overflow: ellipsis;
    overflow: hidden;
}
.table_sticky_th_column {
  /* background: #fff; */
  position: sticky;
  left: 61px;
  z-index: 1;
}
.table_sticky_th_column_left_250 {
  left: 250px;
}

th {
  border: 0;
  padding: 2px 20px;
}

td {
  position: relative;
  border: 1px solid gray;
  padding: 8px 20px;
}

@keyframes shine {
  to {
    background-position-y: -400%;
  }
}

.sk_tr_disabled td::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* 
  background: rgba(0, 0, 0, 0.2); 
  border-radius: 5px;
  */
  background: #eee;
  background: linear-gradient(0deg, rgba(127, 201, 255, 0.2) 10%, rgba(0, 148, 255, 0.2) 10%, rgba(200, 200, 200, 0.5) 20%, rgba(127, 201, 255, 0.2) 50%);
  background-size: 100% 200%;
  animation: 3s shine linear infinite;
}